<template>
  <div>
    <v-container class="mx-auto">
      <v-navigation-drawer v-model="drawer" app disable-resize-watcher>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="item.to"
          link
          exact
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-title v-text="item.title" />
        </v-list-item>
      </v-navigation-drawer>

      <v-app-bar :clipped-left="clipped" fixed app density="compact" flat color="white">
        <v-app-bar-nav-icon
          class="hidden-md-and-up"
          @click.stop="drawer = !drawer"
        />
        <!-- <v-btn icon @click.stop="miniVariant = !miniVariant">
          <v-icon>mdi-{{ `chevron-${miniVariant ? 'right' : 'left'}` }}</v-icon>
        </v-btn>
        <v-btn icon @click.stop="clipped = !clipped">
          <v-icon>mdi-application</v-icon>
        </v-btn>
        <v-btn icon @click.stop="fixed = !fixed">
          <v-icon>mdi-minus</v-icon>
        </v-btn> -->
        <v-img
          src="/logo_black_200.png"
          class="mx-5"
          contain
          height="75"
          width="75"
          max-width="75"
          @click="goHome"
        />
        <!-- <v-toolbar-title
          class="subheading mr-5"
          @click="goHome"
          v-text="title"
        /> -->
        <v-tabs class="hidden-sm-and-down">
          <v-tab
            v-for="(item, i) in items"
            :key="i"
            :to="item.to"
            :href="item.href"
            link
            exact
          >
            {{ item.title }}
          </v-tab>
        </v-tabs>
        <v-spacer />
        <v-btn icon color="primary" @click="goInstagram">
          <v-icon>mdi-instagram</v-icon>
        </v-btn>
        <v-btn icon color="primary" @click="goYoutube">
          <v-icon>mdi-youtube</v-icon>
        </v-btn>
        <!-- <v-text-field
          append-icon="mdi-magnify"
          flat
          hide-details
          solo-inverted
          style="max-width: 300px;"
        /> -->
      </v-app-bar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      clipped: false,
      drawer: false,
      fixed: false,
      items: [
        {
          icon: 'mdi-apps',
          title: 'Home',
          to: '/',
        },
        // {
        //   icon: 'mdi-chart-bubble',
        //   title: 'Works',
        //   to: '/project',
        // },
        // {
        //   icon: 'mdi-chart-bubble',
        //   title: 'Centipede',
        //   to: '/centipede',
        // },
        // {
        //   icon: 'mdi-chart-bubble',
        //   title: 'Congklak',
        //   to: '/congklak-reimagine',
        // },
        // {
        //   icon: 'mdi-chart-bubble',
        //   title: 'HargaDiskon.id',
        //   href: 'https://hargadiskon.id',
        // },
        {
          icon: 'mdi-chart-bubble',
          title: 'Contact',
          to: '/contact',
        },
        {
          icon: 'mdi-chart-bubble',
          title: 'Gallery',
          to: '/gallery',
        },
        {
          icon: 'mdi-chart-bubble',
          title: 'Store',
          href: 'https://lolanano.com',
        },
      ],
      miniVariant: false,
      title: 'andikanu.com',
    }
  },
  watch: {
    // drawer(oldVal, newVal) {
    //   if (this.$vuetify.breakpoint.mdAndUp) {
    //     return false
    //   }
    // }
  },
  updated() {
    // if (this.$vuetify.breakpoint.mdAndUp) {
    //   this.drawer = false
    // }
  },
  methods: {
    goHome() {
      this.$router.push({ path: `/` })
    },
    goInstagram() {
      window.open('https://instagram.com/andikanugraha', '_blank')
    },
    goYoutube() {
      window.open(
        'https://www.youtube.com/channel/UCen44AsghRs-wWosIv8Jepg',
        '_blank'
      )
    },
  },
}
</script>
<style></style>
